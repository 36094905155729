<template>
  <div>
    <v-card>
      <v-card-title>
      <v-icon color="primary">
        mdi-airplane-clock
      </v-icon>
        <span class="pl-2">Time Table</span>
        <small class="pl-2">(Trip ID: {{ $route.params.trip_id }})</small>
        <v-spacer></v-spacer>
        <v-btn depressed color="secondary" @click="$router.go(-1)" class="mx-1">
          Back
          <v-icon right dark> mdi-keyboard-return </v-icon>
        </v-btn>
      </v-card-title>
        <schedule-table
        :headers="headers"
        :tripSchedule="tripSchedule"
        :loading="isLoading"
        :show-notification="true"
        @send-notification="sendNotification"
        ></schedule-table>
    </v-card>
  </div>
</template>

<script>

import {
  mdiAccountCheck,
  mdiPlayCircleOutline,
  mdiAirplane,
} from "@mdi/js";

import scheduleTable from './schedule-table.vue';
import auth from '@/services/AuthService'
export default {
  components: {
    scheduleTable
  },
  data() {
    return {
      tripCountPerDay: 0,
      tripSchedule: [],
      headers: [],
      isLoading: false,
      search: "",
      icons: {
        mdiAccountCheck,
        mdiAirplane,
        mdiPlayCircleOutline,
      },
    };
  },
  watch: {
  },
  mounted() {
    let trip_id = this.$route.params.trip_id;
    this.loadPlannedTrips(trip_id);
  },
  methods: {
    loadPlannedTrips(trip_id) {
      this.isLoading = true;
      axios
        .get(`/trips/schedule/${trip_id}`)
        .then((response) => {
            this.tripCountPerDay = response.data.tripCountPerDay;
            this.tripSchedule = response.data.tripSchedule;
            //create array of headers with tripCountPerDay
            this.headers = [];
            for(let i=0; i<this.tripCountPerDay; i++){
                this.headers.push({
                    text: `Round ${i+1}`,
                    value: `round_${i+1}`,
                    align: "center",
                });
            }
        })
        .catch((error) => {
          this.$notify({
            title: "Error",
            text: "Error while retrieving planned trips",
            type: "error",
          });
          console.log(error);
          auth.checkError(error.response.data.message, this.$router, this.$swal);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    sendNotification(reservation, index){
        //show swal with textarea
        this.$swal({
            input: 'textarea',
            inputPlaceholder: 'Please enter the notification message here',
            inputAttributes: {
            'aria-label': 'Please enter the notification message'
            },
            title: "Send notification",
            html: "Please enter the notification message",
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Send notification",
        }).then((result) => {
            if (result.isConfirmed) {
            axios
                .post(`/planned-trips/notify`, {
                id: reservation.id,
                message: result.value,
                })
                .then((response) => {
                this.$notify({
                    title: "Success",
                    text: "Notification sent successfully",
                    type: "success",
                });
                if(response.status == 201)
                    this.$swal("Info", response.data.message, "info");
                })
                .catch((error) => {
                this.$notify({
                    title: "Error",
                    text: "Error while sending notification",
                    type: "error",
                });
                console.log(error);
                this.$swal("Error", error.response.data.message, "error");
                });
            }
        });
    },
  },
};
</script>
