<template>
  <v-data-table
    item-key="name"
    :loading="loading"
    loading-text="Loading... Please wait"
    :headers="headers"
    :items="tripSchedule"
    :search="search"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Search"
        class="mx-4"
      ></v-text-field>
    </template>

    <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
      <a @click.stop="displayStop(item[header.value].stop.id)">{{
        item[header.value].stop.name
      }}</a>
      <br />
      {{tConvert(item[header.value].planned_timestamp)}}
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    tripSchedule: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    headers:{
        type: Array,
        default: () => []
    }
  },
  components: {},
  data() {
    return {
      search: "",
    };
  },
  mounted() {
  },
  methods: {
    displayStop(stop_id) {
      this.$router.push({
        name: "view-stop",
        params: { stop_id: stop_id },
      });
    },
    tConvert(time) {
      if (time == null) {
        return null;
      }
      // Check correct time format and split into components
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
  },
};
</script>
